<template>
  <div class="detail">
    <Header title="影片营销信息" @back="onBack(false)"></Header>
    <a-spin :spinning="loading">
      <a-form ref="formRef" :model="modelRef" scrollToFirstError name="formRef"
              :labelCol="{span: 4 }"
              :wrapperCol="{span: 18 }"
      >

        <h3 class="ui-title">影片信息</h3>
        <a-descriptions style="margin-top: 30px; margin-left: 100px;">
          <a-descriptions-item label="影片名称">{{ modelRef.name }}</a-descriptions-item>
          <a-descriptions-item label="是否上映">{{ modelRef.isOnline ? '是' : '否' }}</a-descriptions-item>
          <a-descriptions-item label="想看人数">{{ modelRef.wantNum }}</a-descriptions-item>
          <a-descriptions-item label="影片标签">
            <span class="ui-label" v-for="item in modelRef.tagList" :key="item.id">
              {{ item.name }}
            </span>
          </a-descriptions-item>
        </a-descriptions>

        <h3 class="ui-title">营销信息</h3>

        <a-form-item label="海报" extra="建议图片比例：80 : 112">
          <a-upload
              :disabled="isSee"
              v-model:file-list="fileList"
              list-type="picture-card"
              action="/admin/ajaxUpload.do"
              accept="image/*"
              @change="handleChange"
          >
            <div v-if="fileList.length < 1">
              <Icon icon="PlusOutlined"></Icon>
              <div style="margin-top: 8px">Upload</div>
            </div>
          </a-upload>
        </a-form-item>

        <a-form-item label="影片评分" name="score" extra="不配置则在小程序展示0分">
          <a-input-number :min="0" :precision="1" :disabled="isSee" style="width: 200px;" v-model:value="modelRef.score" placeholder="请输入影片评分" />
        </a-form-item>

        <a-form-item label="基础想看人数" name="wantBaseNum" extra="影片的想看人数 = 实际想看人数 + 基础想看人数">
          <a-input :disabled="isSee" style="width: 200px;" v-model:value="modelRef.wantBaseNum" placeholder="请输入基础想看人数" />
        </a-form-item>

        <a-form-item label="是否可评价" name="evaluate" extra="配置影片不可评价则用户不可评价该影片">
          <a-switch :disabled="isSee" v-model:checked="modelRef.evaluate" />
        </a-form-item>

        <a-form-item label="分享海报" :rules="[{required: true, message: '必填项不允许为空'}]">
          <a-form-item-rest>
            <a-button :disabled="isSee" type="primary" @click="onAddPoster">新增</a-button>
            <div style="margin-top: 20px;" v-if="posterList.length">
              <a-table :pagination="false" :columns="posterColumns" :dataSource="posterList" rowKey="id"
                       :scroll="{ x: 550 }">
                <template #bodyCell="{ column, record, index }">
                  <template v-if="column.key === 'imageUrl'">
                    <a-image :width="50" :src="record.imageUrl" />
                  </template>

                  <template v-if="column.key === 'action'">
                    <a-dropdown :trigger="['click', 'hover']">
                      <a-button @click.prevent>操作 <Icon icon="DownOutlined"></Icon>
                      </a-button>
                      <template #overlay>
                        <a-menu>
                          <div @click="onEditPoster(record, index)">
                            <a-menu-item>
                              编辑
                            </a-menu-item>
                          </div>
                          <div @click="onDeletePoster(index)">
                            <a-menu-item>
                              删除
                            </a-menu-item>
                          </div>
                        </a-menu>
                      </template>
                    </a-dropdown>
                  </template>
                </template>
              </a-table>
            </div>
          </a-form-item-rest>
        </a-form-item>

        <a-form-item style="text-align: center;margin-top: 20px;">
          <a-button v-if="!isSee" type="primary" html-type="submit" @click="onSubmit(true)">提交</a-button>
          <a-button style="margin-left: 20px;" @click="onBack(false)">返回</a-button>
        </a-form-item>
      </a-form>
    </a-spin>

    <!--    分享海报    -->
    <a-modal v-model:visible="showSharePoster" destroyOnClose :title="isPosterEdit ? '编辑' : '新增'" width="750px" @ok="onAddPosterOk">
      <a-spin :spinning="loading">
        <a-form ref="addPerformerForm" scrollToFirstError :model="posterModelRef" name="addPerformerForm" :labelCol="{span: 5}" :wrapperCol="{span: 15 }">
          <a-form-item label="标题" name="name" :rules="[{ required: true, message: '必填项不允许为空' }]">
            <a-input v-model:value="posterModelRef.name" placeholder="请输入标题"/>
          </a-form-item>

          <a-form-item label="优先级" name="sort" :rules="[{ required: true, message: '必填项不允许为空' }]">
            <a-input-number style="width: 100%" min="0" :precision="0" v-model:value="posterModelRef.sort" placeholder="请输入优先级"/>
          </a-form-item>

          <a-form-item label="分享海报" :rules="[{required: true, message: '必填项不允许为空'}]" extra="建议图片比例：590 : 826">
            <a-upload
                v-model:file-list="posterFileList"
                list-type="picture-card"
                action="/admin/ajaxUpload.do"
                accept="image/*"
                @change="handlePosterChange"
            >
              <div v-if="posterFileList.length < 1">
                <Icon icon="PlusOutlined"></Icon>
                <div style="margin-top: 8px">Upload</div>
              </div>
            </a-upload>
          </a-form-item>
        </a-form>
      </a-spin>
    </a-modal>
  </div>
</template>

<script>
import Header from '@/components/header/header.vue';
import { Icon } from '@/components/icon/icon.js';
import {getFilmInfoDetail, updateFilmInfo} from "@/service/modules/cinema";
export default {
  components: {
    Header,
    Icon
  },
  props: {
    isEdit: {
      type: Boolean,
      default: true
    },
    isSee: {
      type: Boolean,
      default: true
    },
    id: {
      type: Number,
      default: 0
    },
  },
  data() {
    return {
      fileList: [],
      posterFileList: [],
      loading: false,
      isPosterEdit: false,
      showSharePoster: false,
      posterModelRef: {},
      modelRef: {
        evaluate: false,
      },
      posterColumns: [{
        title: '标题',
        dataIndex: 'name',
      }, {
        title: '海报',
        key: 'imageUrl',
      }, {
        title: '优先级',
        dataIndex: 'sort',
      }, {
        title: '操作',
        key: 'action',
        width: 100,
      }],
      posterList: [],
    };
  },
  created() {
    if(this.id) {
      this.getData();
    }
  },
  methods: {
    onBack(isRef) {
      this.$emit('back', isRef);
    },
    // 获取影片详情
    async getData() {
      this.loading = true;
      try {
        let ret = await getFilmInfoDetail({
          id: this.id
        })
        this.loading = false;
        if(ret.code === 200) {
          this.posterList = ret.data.posterList ? ret.data.posterList : [];
          this.modelRef = ret.data;
        }
        if(ret.data.posterUrl){
          this.fileList = ret.data.posterUrl.split(',').map(item=>{
            return {
              url: item,
            }
          })
        }
      } catch(e) {
        this.loading = false
      }
    },
    // 提交修改数据
    onSubmit(isBack) {
      this.$refs.formRef.validateFields().then(async () => {
        if(this.posterList.length === 0) {
          this.$message.error('分享海报不能为空！');
          return;
        }
        // this.modelRef.posterUrl = this.fileList;
        let postData = JSON.parse(JSON.stringify(this.modelRef));
        postData.posterList = JSON.parse(JSON.stringify(this.posterList));
        const imgList = [];
        this.fileList.forEach(item=>{
          imgList.push(item.url);
        });
        postData.posterUrl = imgList.join(',');
        this.loading = true;
        try {
          postData.id = this.id;
          let ret = await updateFilmInfo(postData);
          this.loading = false;
          if(ret.code === 200) {
            this.$message.success('操作成功');
            this.onBack(isBack);
          }
        } catch(e) {
          this.loading = false;
        }
      })
    },
    handleChange(info) {
      if (info.file.status === 'done') {
        //console.log(info.file);
        this.fileList = info.fileList;
        const $fileList = [...this.fileList];
        this.fileList = $fileList.map(file => {
          if (file.response) {
            file.url = file.response.data.imgUrl;
          }
          return file;
        });
      }
    },
    // 新增分享海报
    onAddPoster() {
      this.posterModelRef = {};
      this.posterFileList = [];
      this.showSharePoster = true;
      this.isPosterEdit = false;
    },
    // 编辑分享海报
    onEditPoster(item, index) {
      this.isPosterEdit = true;
      this.showSharePoster = true;
      this.posterId = index;
      this.posterModelRef = JSON.parse(JSON.stringify(item));
      if(item.imageUrl) {
        this.posterFileList = item.imageUrl.split(',').map(info => {
          return {
            url: info
          }
        })
      }
    },
    // 处理分享海报图片
    handlePosterChange(info) {
      if (info.file.status === 'done') {
        //console.log(info.file);
        this.posterFileList = info.fileList;
        const $fileList = [...this.posterFileList];
        this.posterFileList = $fileList.map(file => {
          if (file.response) {
            file.url = file.response.data.imgUrl;
          }
          return file;
        });
      }
    },
    // 添加/编辑 分享海报完成
    onAddPosterOk() {
      this.$refs.addPerformerForm.validateFields().then(async () => {
        if(this.posterFileList.length === 0) {
          this.$message.error('分享海报不能为空！');
          return;
        }
        if(this.isPosterEdit) {
          this.posterList.forEach((item, index) => {
            if(index === this.posterId) {
              item.name = this.posterModelRef.name;
              item.imageUrl = this.posterFileList[0].url;
              item.sort =  this.posterModelRef.sort;
              this.showSharePoster = false;
            }
          })
        } else {
          let obj = {
            name: this.posterModelRef.name,
            imageUrl: this.posterFileList[0].url,
            sort: this.posterModelRef.sort
          }
          this.posterList.push(obj);
          this.showSharePoster = false;
          this.posterModelRef = {};
          this.posterFileList = [];
        }
      })
    },
    // 删除分享海报
    onDeletePoster(i) {
      this.posterList = this.posterList.filter((item, index) => i !== index);
    },
  }
};
</script>

<style lang="less" scoped>
.detail {
  .ui-title {
    position: relative;
    margin: 20px 0 10px;
    padding-left: 10px;
    font-size: 16px;
    font-weight: 600;
  }

  .ui-title::before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    width: 4px;
    height: 20px;
    border-radius: 2px;
    background-color: #1890ff;
    transform: translateY(-50%);
  }
}

.ui-label {
  margin-right: 10px;
  padding: 1px 10px;
  border: 1px solid #262626;
  border-radius: 10px;
}
</style>